import { render, staticRenderFns } from "./VideoPolling.vue?vue&type=template&id=715b7454&scoped=true"
import script from "./VideoPolling.vue?vue&type=script&lang=js"
export * from "./VideoPolling.vue?vue&type=script&lang=js"
import style0 from "./VideoPolling.vue?vue&type=style&index=0&id=715b7454&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "715b7454",
  null
  
)

export default component.exports